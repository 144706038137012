@import "src/scss/module";

.BlogPostCard {
  --bs-card-border-width: none;
  --bs-card-border-radius: 0;
  --bs-card-bg: transparent;
  height: 100%;
  transition: 0.15s;

  &:hover {
    transform: scale(1.02);

    :global {
      .card-title {
        color: #{$green};
      }
    }
  }

  :global {
    .card-img {
      img {
        width: 100%;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    .card-header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-transform: uppercase;
      font-size: #{$font-size-xs};
      background-color: transparent;
      border: 0;
      padding: 0.75rem 0;

      i {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #{$gradient-cyan};
        border-radius: 50%;
      }
    }

    .card-title {
      flex-grow: 1;
      transition: 0.15s;
    }

    .card-footer {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      padding: 0;
      gap: 0.25rem;

      i {
        display: inline-block;
        width: 12px;
        height: 14px;
        background-color: #{$green};
        clip-path: #{$hexagon-clip-path-alt};
      }
    }
  }
}
